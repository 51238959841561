import { Trans, useTranslation } from 'react-i18next'
import { TextBodyRegular } from './Typography'

export const InvestWarningBanner = (): JSX.Element => {
  const { t } = useTranslation('nsInvestWarning')
  return (
    <div className='fixed w-full z-50' style={{ backgroundColor: '#0BB5A5' }}>
      <div className='max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8 flex'>
        {/* Content */}
        <div className='pr-16 sm:text-center sm:px-16 flex-grow'>
          <p className='font-medium text-white flex flex-col md:flex-row justify-center md:space-x-12 space-y-4 md:space-y-0 py-1'>
            <span className='flex items-center space-x-2 md:justify-center'>
              <TextBodyRegular>
                <Trans
                  t={t}
                  i18nKey={`message`}
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        target='_blank'
                        href='https://trazable.io/riesgos-de-la-inversion-en-criptomonedas/'
                        className='underline'
                        rel='noreferrer'
                      />
                    ),
                  }}
                />
              </TextBodyRegular>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}
