import { useTranslation } from 'react-i18next'
import imgTelegram from '@/assets/images/telegram-logo.svg'

export const TelegramFloatingFollow = (): JSX.Element => {
  const { t, i18n } = useTranslation('nsTelegramFloatingFollow')

  return (
    <a
      href={t('telegramLink')}
      hrefLang={i18n.language}
      target='_blank'
      rel='noreferrer'
      className='z-50'
    >
      <div className='fixed bottom-0 right-0 mr-6 mb-6 rounded-full shadow-xl z-50'>
        <img
          className='m-auto tl_main_logo'
          src={imgTelegram}
          alt='Telegram logo'
          width='64'
          height='64'
        />
      </div>
    </a>
  )
}
