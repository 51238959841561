import { Footer } from '@/layout/Footer'
import { Menu, Popover, Transition } from '@headlessui/react'
import { GlobeIcon, DocumentTextIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, MenuIcon, XIcon } from '@heroicons/react/solid'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { navigate, PageProps } from 'gatsby'
import { PAGES } from '@/routes'
import {
  Avatar,
  CheckboxField,
  InputField,
  Modal,
  SIZE,
} from '@blockchain-traceability-sl/tailwind-components'
import { useAuth } from '@/features/auth/use-auth'
import classNames from 'classnames'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { UserCredentials } from '@/features/auth/entity'
import ReCAPTCHA from 'react-google-recaptcha'
import { useConfig } from '@/config'
import { customErrorToNotify, useNotify } from '@/hooks/use-notify'
import { Helmet } from 'react-helmet'
import { Trans, useTranslation } from 'react-i18next'
import { TelegramFloatingFollow } from '@/components/TelegramFloatingFollow'
import { Link } from '@/components/Link'
import { changePathLanguage, getCurrentPageLanguage, getTranslatedPath } from '@/i18n/utils'
import { defaultLanguage, languages } from '@/i18n'
import { useLocation } from '@reach/router'
import { requestActivateAccountEmail } from '@/features/auth/service'
import { isBrowser } from '@/utils'
import { useSocket } from '@/features/socket/use-socket'
import { customErrorToTrack } from '@/utils/error-to-analytics'
import { useSearchParams } from '@/hooks/use-search-params'
import { useScript } from '@/hooks/use-script'
import { useCookiesConfig } from '@/features/cookies/use-cookies'
import { CookieBanner } from '@/features/cookies/CookieBanner'
import {
  H1Bold,
  H2Bold,
  H3Regular,
  H4Bold,
  H5Regular,
  TextBodyRegular,
  TextButton,
} from '@/components/Typography'
import {
  ButtonMain,
  ButtonMainLink,
  ButtonSecondaryLink,
  FeatureCard,
} from '@/components/TokenLandingComponents'

// Images
import imgOG from '@/assets/images/og/ogimage.png'
import imgLogo from '@/assets/images/brand/Trazable_Logo_W.png'
import imgLogoBlack from '@/assets/images/brand/Trazable_Logo_Black.png'
import imgHeaderPattern from '@/assets/images/token-intro-background.gif'
import imgHeaderRectangle from '@/assets/images/rectangle-background.svg'
import imgCoinBackground from '@/assets/images/coin-background.png'
import imgSquaresBackground from '@/assets/images/squares-background.svg'
import imgTokenChart from '@/assets/images/token-chart.png'
import imgPartnershipEActivate from '@/assets/images/partnerships/EActívate.svg'
import imgPartnershipEitClimateKic from '@/assets/images/partnerships/eitClimate-Kic.svg'
import imgPartnershipEitFood from '@/assets/images/partnerships/eitFood.svg'
import imgPartnershipKmzero from '@/assets/images/partnerships/Kmzero.png'
import imgPartnershipLanzadera from '@/assets/images/partnerships/Lanzadera.png'
import imgPartnershipMinisterioDeCiencia from '@/assets/images/partnerships/MinisterioDeCiencia.svg'
import imgFeatureHold from '@/assets/images/tokenFeatures/hold.png'
import imgFeatureStake from '@/assets/images/tokenFeatures/stake.png'
import imgFeatureAirdrops from '@/assets/images/tokenFeatures/airdrops.png'
import imgFeatureUtility from '@/assets/images/tokenFeatures/utility.png'
import imgRoadmapDesktopESV2 from '@/assets/images/tokenRoadmap-v2/roadmap-desktop_es_v2.png'
import imgRoadmapDesktopENV2 from '@/assets/images/tokenRoadmap-v2/roadmap-desktop_en_v2.png'
import imgRoadmapMobileESV2 from '@/assets/images/tokenRoadmap-v2/roadmap-mobile_es_v2.png'
import imgRoadmapMobileENV2 from '@/assets/images/tokenRoadmap-v2/roadmap-mobile_en_v2.png'
import imgPressElMundo from '@/assets/images/press/el-mundo.png'
import imgPressBusinessInsider from '@/assets/images/press/business-insider.png'
import imgPressCoinTelegraph from '@/assets/images/press/coin-telegraph.png'
import imgPressEuropaPress from '@/assets/images/press/europa-press.png'
import imgPressExpansion from '@/assets/images/press/expansion.png'
import imgPressInformacion from '@/assets/images/press/informacion.png'
import imgFarmSeparator from '@/assets/images/tokenFarm/separator.png'
import imgUniswapLogo from '@/assets/images/tokenFarm/uniswap-logo-and-wordmark.png'

// Whitepaper
import pdfWhitepaperES from '@/assets/whitepaper/whitepaper_trz_v1.1_es.pdf'
import pdfWhitepaperEN from '@/assets/whitepaper/whitepaper_trz_v1.1_en.pdf'
import { useToggle } from '@/hooks/use-toggle'
import { SectionUtilities } from '@/components/SectionUtilities'
import { InvestWarningBanner } from '@/components/InvestWarningBanner'

/**
 * Screen Token
 *
 * @param login - QueryParam to default open login form
 */
const TokenPage = ({ pageContext }: PageProps<never, { language: string }>): JSX.Element => {
  const { t, i18n } = useTranslation('nsToken')
  const location = useLocation()

  const config = useConfig()
  useSocket()

  if (i18n.language !== pageContext.language) {
    i18n.changeLanguage(pageContext.language)
  }

  const pdfWhitepaperByLang = useMemo(() => {
    if (i18n.language.startsWith('es')) {
      return pdfWhitepaperES
    } else {
      return pdfWhitepaperEN
    }
  }, [i18n.language])

  const [authModalShow, setAuthModalShow] = useState<boolean>(false)
  const [authModalMode, setAuthModalMode] = useState<'signin'>('signin')

  const signinRecaptchaRef = useRef<ReCAPTCHA>(null)

  const initialAuthFieldFocusRef = useRef<HTMLInputElement>(null)

  const roadmapDesktopImage: string = useMemo(() => {
    return i18n.language.startsWith('es') ? imgRoadmapDesktopESV2 : imgRoadmapDesktopENV2
  }, [i18n.language])

  const roadmapMobileImage: string = useMemo(() => {
    return i18n.language.startsWith('es') ? imgRoadmapMobileESV2 : imgRoadmapMobileENV2
  }, [i18n.language])

  const auth = useAuth()
  const cookiesConfig = useCookiesConfig()
  const notify = useNotify()
  const searchParams = useSearchParams<{ login: string }>()
  useScript(
    i18n.language.startsWith('es')
      ? 'https://acumbamail.com/newform/dynamic/js/UD2tP4c8H5zygp9QgsX6r7UY/30630/'
      : 'https://acumbamail.com/newform/dynamic/js/UD2tP4c8H5zygp9QgsX6r7UY/30760/'
  )

  const [bannerShow] = useToggle(true)

  useEffect(() => {
    if (searchParams.login === '') {
      setAuthModalMode('signin')
      setAuthModalShow(true)
    }
  }, [searchParams.login])

  const formSignIn = useFormik<
    UserCredentials & { recaptcha: null | string; remember: boolean; termAndConditions: boolean }
  >({
    initialValues: {
      email: '',
      password: '',
      remember: true,
      recaptcha: null,
      termAndConditions: false,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
      recaptcha: Yup.string().required(t('nsValidations:required')).nullable(),
      remember: Yup.boolean(),
      termAndConditions: Yup.boolean()
        .required(t('nsValidations:required'))
        .oneOf([true], t('nsValidations:required')),
    }),
    onSubmit: ({ recaptcha, ...data }) => {
      formSignIn.setSubmitting(true)
      auth
        ?.signin({
          ...data,
          reCaptchaToken: recaptcha as string,
        })
        .then(() => {
          window.analytics?.track('CUSTOMER_LOGIN_PLATFORM', { Email: data.email })
          navigate(getTranslatedPath(location.pathname, PAGES.PROFILE))
        })
        .catch(error => {
          setAuthModalShow(false)
          window.analytics?.track('CUSTOMER_LOGIN_PLATFORM_ERROR', {
            Email: data.email,
            ...customErrorToTrack(error.response?.data, error.response?.status),
          })

          if (error.response.data.code === 9) {
            notify.error({
              title: t('nsNotifications:error.9.title'),
              description: (
                <Trans
                  t={t}
                  i18nKey='nsNotifications:error.9.description'
                  components={{
                    br: <br />,
                    a: (
                      <button
                        className='underline text-blue-500'
                        onClick={() => {
                          requestActivateAccountEmail(
                            data.email,
                            `${location.origin}${getTranslatedPath(
                              location.pathname,
                              PAGES.VERIFY_ACCOUNT
                            )}`
                          )
                          notify.clear()
                        }}
                      />
                    ),
                  }}
                />
              ) as unknown as string,
              actionText: t('nsNotifications:error.9.action'),
            })
          } else {
            notify.error(customErrorToNotify(error.response?.data))
          }
        })
        .finally(() => {
          formSignIn.setSubmitting(false)
          formSignIn.resetForm()
          setAuthModalShow(false)
        })
    },
  })

  const handleClickAccess = useCallback(() => {
    if (auth && auth.isAuthenticated) {
      navigate(getTranslatedPath(location.pathname, PAGES.PROFILE))
    } else {
      setAuthModalMode('signin')
      setAuthModalShow(true)
    }
  }, [auth, location.pathname])

  const features = useMemo(
    () => [
      {
        name: t('nsSectionTokenFeatures:features.hold.name'),
        description: t('nsSectionTokenFeatures:features.hold.description'),
        imageSrc: imgFeatureHold,
      },
      {
        name: t('nsSectionTokenFeatures:features.stake.name'),
        description: t('nsSectionTokenFeatures:features.stake.description'),
        imageSrc: imgFeatureStake,
      },
      {
        name: t('nsSectionTokenFeatures:features.airdrops.name'),
        description: t('nsSectionTokenFeatures:features.airdrops.description'),
        imageSrc: imgFeatureAirdrops,
      },
      {
        name: t('nsSectionTokenFeatures:features.utilidad.name'),
        description: t('nsSectionTokenFeatures:features.utilidad.description'),
        imageSrc: imgFeatureUtility,
      },
    ],
    [t]
  )

  // Reset auth forms on change between login and registry
  useEffect(() => {
    formSignIn.resetForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authModalMode])

  // Analytics
  const navigationViewEventTracked = useRef(false)
  useEffect(() => {
    setTimeout(() => {
      if (isBrowser() && window.analytics?.track && !navigationViewEventTracked.current) {
        window.analytics?.track('NAVIGATION_VIEW_HOMEPAGE')
        window?.fbq && window.fbq('track', 'ViewContent')
        navigationViewEventTracked.current = true
      }
    }, 500)
  })

  const handleToggleAuthModalShow = useCallback(() => {
    setAuthModalShow(false)
  }, [])

  const handleClickBuyTokens = useCallback((exchange: 'Bittrex' | 'PancakeSwap' | 'Uniswap') => {
    window.analytics?.track('ACTION_CLICK_EXCHANGE', { Name: exchange })
  }, [])

  const handleClickGoToBuyTokens = useCallback(() => {
    document.getElementById('exchanges')?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  const heroContent = useMemo(() => {
    return (
      <div className='grid grid-cols-1 md:grid-cols-1 gap-12'>
        <div className='flex flex-col justify-center gap-4'>
          <H2Bold className='text-white text-center'>{t('exchange.step3.title')}</H2Bold>
          <ButtonMain className=' m-auto w-72' onClick={handleClickGoToBuyTokens}>
            <TextButton>{t('exchange.step3.action')}</TextButton>
          </ButtonMain>
          <TextBodyRegular className=' m-auto text-white text-center w-72'>
            {t('exchange.step3.description')}
          </TextBodyRegular>
        </div>
      </div>
    )
  }, [t, handleClickGoToBuyTokens])

  const exchangeSection = useMemo(
    () => (
      <section
        id='exchanges'
        className='relative pt-16 pb-6 md:pb-12'
        style={{ background: 'rgba(16, 42, 52, 0.7)' }}
      >
        <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
          <H1Bold className='text-white hidden md:block'>
            <Trans
              t={t}
              i18nKey='nsSectionTokenExchanges:title'
              components={{
                span: (
                  <span className='bg-clip-text text-transparent bg-gradient-to-t from-yellow-400 to-yellow-600 sm:pb-5' />
                ),
              }}
            />
          </H1Bold>
          <H1Bold className='text-white md:hidden' style={{ fontSize: 55 }}>
            <Trans
              t={t}
              i18nKey='nsSectionTokenExchanges:title'
              components={{
                span: (
                  <span className='bg-clip-text text-transparent bg-gradient-to-t from-yellow-400 to-yellow-600 sm:pb-5' />
                ),
              }}
            />
          </H1Bold>
          <H3Regular className='text-white'>{t('nsSectionTokenExchanges:subTitle')}</H3Regular>
          <div className='my-12 flex flex-col md:flex-row justify-center space-y-10 md:space-x-12 md:space-y-0'>
            {/* Uniswap */}
            {
              <a
                onClick={() => handleClickBuyTokens('Uniswap')}
                href={config.US}
                className='flex justify-center items-center mx-auto md:mx-0'
                style={{
                  background: 'rgba(255, 255, 255)',
                  width: 336,
                  height: 112,
                  borderRadius: 30,
                }}
                target='_blank'
                rel='noreferrer'
              >
                <img src={imgUniswapLogo} alt='Uniswap' style={{ height: 40 }} />
              </a>
            }
          </div>
        </div>
      </section>
    ),
    [config.US, handleClickBuyTokens, t]
  )

  return (
    <>
      <Helmet>
        <title>{t('meta.title')}</title>
        <meta name='description' content={t('meta.description')} />
        <meta
          name='robots'
          content='index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
        />
        <meta name='facebook-domain-verification' content='gdt37htl4djqwmarnkdyct76lx1zos' />

        <link rel='canonical' href={`https://token.trazable.io`} />

        {/* Og Meta */}
        <meta property='og:locale' content='es_ES' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content={t('meta.title')} />
        <meta property='og:description' content={t('meta.description')} />
        <meta property='og:url' content={`https://token.trazable.io`} />
        <meta property='og:site_name' content='Trazable Token' />
        <meta property='article:publisher' content='https://www.facebook.com/trazable/' />
        <meta property='article:modified_time' content='2021-04-09T11:41:00+00:00' />
        <meta property='og:image' content={imgOG} />
        <meta property='og:image:width' content='1200' />
        <meta property='og:image:height' content='630' />
        {/* End OG Meta */}

        {/* Twitter Meta */}
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@iotrazable' />
        <meta name='twitter:label1' content='Tiempo de lectura' />
        <meta name='twitter:data1' content='3 minutos' />
        {/* End Twitter Meta */}

        <script type='application/ld+json' className='yoast-schema-graph'>{`
          {"@context":"https://schema.org","@graph":[{"@type":"WebSite","@id":"https://token.trazable.io/#website","url":"https://token.trazable.io/","name":"Trazable Token","description":"Trazabilidad end-to-end de alimentos","potentialAction":[{"@type":"SearchAction","target":"https://token.trazable.io/?s={search_term_string}","query-input":"required name=search_term_string"}],"inLanguage":"es"},{"@type":"ImageObject","@id":"https://token.trazable.io/#primaryimage","inLanguage":"es","url":"https://token.trazable.io/assets/images/icono-trazable-150.png","width":150,"height":150,"caption":"trazable logo150 farm to fork blockchain traceability"},{"@type":"WebPage","@id":"https://token.trazable.io/#webpage","url":"https://token.trazable.io/","name":"Trazable - Trazabilidad end-to-end de alimentos","isPartOf":{"@id":"https://token.trazable.io/#website"},"primaryImageOfPage":{"@id":"https://token.trazable.io/#primaryimage"},"datePublished":"2019-01-22T08:16:34+00:00","dateModified":"2021-04-09T11:41:00+00:00","description":"Optimiza tu cadena de suministro con informaci\u00f3n a tiempo real de toda la cadena, gracias a nuestra plataforma blockchain","inLanguage":"es","potentialAction":[{"@type":"ReadAction","target":["https://token.trazable.io/"]}]}]}
        `}</script>

        {/* Google Tag Manager */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-T432GJH');
        `}</script>
        )}
        {/* End Google Tag Manager */}

        {/* Segment analytics */}
        {cookiesConfig?.isConfigured && cookiesConfig.config?.analytics && (
          <script>{`
          !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey=window.GATSBY_APP_SEGMENT_WRITEKEY;;analytics.SNIPPET_VERSION="4.15.3";
          setTimeout(() => analytics.load(window.GATSBY_APP_SEGMENT_WRITEKEY), 500);
          analytics.page();
          }}();
        `}</script>
        )}

        {/* Disallow cache */}
        <meta http-equiv='cache-control' content='max-age=0' />
        <meta http-equiv='cache-control' content='no-cache' />
        <meta http-equiv='expires' content='0' />
        <meta http-equiv='expires' content='Tue, 01 Jan 1980 1:00:00 GMT' />
        <meta http-equiv='pragma' content='no-cache' />
      </Helmet>
      {/* Invest warning banner */}
      <InvestWarningBanner />

      <div style={{ backgroundColor: '#FFFFFF' }}>
        <div className='relative overflow-hidden'>
          {/* Top section */}
          <div
            className='bg-gray-900 relative bg-no-repeat bg-cover'
            style={{
              backgroundImage: `url(${imgHeaderPattern})`,
            }}
          >
            <div
              className='absolute w-full h-full'
              style={{ backgroundColor: 'rgba(22, 4, 49, 0.4)' }}
            >
              <div
                className='absolute w-full h-full bg-right bg-no-repeat right-1/10'
                style={{
                  backgroundImage: `url(${imgHeaderRectangle})`,
                }}
              />
            </div>

            {/* HEADER */}
            <Popover
              as='header'
              id='header'
              className={classNames('fixed w-full z-30 bg-gray-900 bg-opacity-80 pb-6', {
                'pt-24 md:pt-16': bannerShow,
              })}
            >
              <div className='pt-6'>
                <nav
                  className='relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6'
                  aria-label='Global'
                >
                  {/* Left top bar */}
                  <div className='flex items-center flex-1'>
                    <div className='flex items-center justify-between w-full md:w-auto'>
                      <a
                        href={`${config.WEBSITE_URL}/${
                          getCurrentPageLanguage(location.pathname) === defaultLanguage
                            ? ''
                            : getCurrentPageLanguage(location.pathname)
                        }`}
                      >
                        <span className='sr-only'>Trazable</span>
                        <img className='h-5 xl:h3 w-auto' src={imgLogo} alt='' />
                      </a>
                      <div className='-mr-2 flex items-center md:hidden'>
                        <Popover.Button className='bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white'>
                          <span className='sr-only'>Open main menu</span>
                          <MenuIcon className='h-6 w-6' aria-hidden='true' />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className='hidden space-x-8 md:flex md:ml-10'></div>
                  </div>

                  {/* Right desktop top bar */}
                  <div className='hidden md:flex md:items-center md:space-x-6'>
                    {/* Language selector */}
                    <Menu as='div' className='inline-block relative text-left z-40'>
                      <div>
                        <Menu.Button className='rounded-full flex items-center text-gray-100 hover:text-gray-300'>
                          <span className='sr-only'>Open options</span>
                          <GlobeIcon className='h-5 w-5' aria-hidden='true' />
                          <ChevronDownIcon className='ml-2 h-5 w-5' aria-hidden='true' />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <div className='py-1'>
                            {languages.map(([lngCode, lngName]) => (
                              <Menu.Item key={lngCode}>
                                {({ active }) => (
                                  <button
                                    onClick={() => changePathLanguage(location.pathname, lngCode)}
                                    className={classNames(
                                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                      'block px-4 py-2 text-sm w-full text-left'
                                    )}
                                  >
                                    {lngName}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    {/* Learn dropdown */}
                    <Menu as='div' className='inline-block relative text-left z-40'>
                      <div>
                        <Menu.Button className='rounded-full flex items-center text-gray-100 hover:text-gray-300'>
                          <span className='sr-only'>Learn options</span>
                          <span className='text-white font-bold'>{t('header.learn')}</span>
                          <ChevronDownIcon className='ml-2 h-5 w-5' aria-hidden='true' />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-100'
                        enterFrom='transform opacity-0 scale-95'
                        enterTo='transform opacity-100 scale-100'
                        leave='transition ease-in duration-75'
                        leaveFrom='transform opacity-100 scale-100'
                        leaveTo='transform opacity-0 scale-95'
                      >
                        <Menu.Items className='origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
                          <div className='py-1'>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='https://trazable.io/como-cambiar-la-red-de-mis-trz-tokens-de-bsc-a-eth-y-viceversa/'
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm w-full text-left'
                                  )}
                                >
                                  {t('header.learns.chainport')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='https://trazable.io/como-participar-en-la-pool-en-uniswap/'
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm w-full text-left'
                                  )}
                                >
                                  {t('header.learns.uniswap.pool')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='https://trazable.io/como-crear-una-wallet-y-agregar-la-binance-smart-chain-y-el-token-trz/'
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm w-full text-left'
                                  )}
                                >
                                  {t('header.learns.metamask.bsc')}
                                </a>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href='https://trazable.io/riesgos-de-la-inversion-en-criptomonedas/'
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm w-full text-left'
                                  )}
                                >
                                  {t('header.learns.risks')}
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>

                    {auth?.isAuthenticated && auth.user ? (
                      <Menu as='div' className='ml-3 relative z-10'>
                        <div>
                          <Menu.Button className='max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white'>
                            <span className='sr-only'>Open user menu</span>
                            <Avatar size={SIZE.SMALL} />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter='transition ease-out duration-100'
                          enterFrom='transform opacity-0 scale-95'
                          enterTo='transform opacity-100 scale-100'
                          leave='transition ease-in duration-75'
                          leaveFrom='transform opacity-100 scale-100'
                          leaveTo='transform opacity-0 scale-95'
                        >
                          <Menu.Items className='origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg pb-1 overflow-hidden bg-white ring-1 ring-black ring-opacity-5 focus:outline-none divide-y divide-gray-100'>
                            <div className='px-4 py-3 bg-gray-50'>
                              <p className='text-sm'>{auth.user.name}</p>
                              <p className='text-sm font-medium text-gray-500 truncate'>
                                {auth.user.email}
                              </p>
                            </div>
                            <div className='py-1'>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    to={PAGES.PROFILE}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                    )}
                                  >
                                    {t('header.profile')}
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={auth.signout}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                    )}
                                  >
                                    {t('header.logout')}
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <>
                        <button
                          onClick={handleClickAccess}
                          className='inline-flex items-center px-4 py-2 border border-transparent text-base font-bold rounded-md text-white'
                          // style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                        >
                          {t('header.access')}
                        </button>
                      </>
                    )}
                  </div>
                </nav>
              </div>

              {/* Right mobile top bar */}
              <Transition
                as={Fragment}
                enter='duration-200 ease-out'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='duration-100 ease-in'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Popover.Panel
                  focus
                  className='absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
                >
                  <div
                    className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 divide-y-2 divide-gray-50'
                    style={{ backgroundColor: '#fff' }}
                  >
                    <div className='pt-5 pb-6 px-5'>
                      <div className='flex items-center justify-between'>
                        <div>
                          <img className='h-5 xl:h3 w-auto' src={imgLogoBlack} alt='Trazable' />
                        </div>
                        <div className='-mr-2'>
                          <Popover.Button className='bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500'>
                            <span className='sr-only'>Close menu</span>
                            <XIcon className='h-6 w-6' aria-hidden='true' />
                          </Popover.Button>
                        </div>
                      </div>

                      {!(auth?.isAuthenticated && auth.user) && (
                        <div className='pb-6 px-5'>
                          <ButtonMain className='mt-6 w-full' onClick={handleClickAccess}>
                            <TextButton>{t('header.access')}</TextButton>
                          </ButtonMain>
                        </div>
                      )}
                    </div>

                    {
                      <div className='grid grid-cols-1 gap-y-4 gap-x-8 mt-6 px-5 py-4'>
                        <p className='text-lg text-gray-500'>{t('header.learn')}</p>
                        <a
                          href='https://trazable.io/como-cambiar-la-red-de-mis-trz-tokens-de-bsc-a-eth-y-viceversa/'
                          className='text-base font-medium text-gray-900 hover:text-gray-700'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('header.learns.chainport')}
                        </a>
                        <a
                          href='https://trazable.io/como-participar-en-la-pool-en-uniswap/'
                          className='text-base font-medium text-gray-900 hover:text-gray-700'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('header.learns.uniswap.pool')}
                        </a>
                        <a
                          href='https://trazable.io/como-crear-una-wallet-y-agregar-la-binance-smart-chain-y-el-token-trz/'
                          className='text-base font-medium text-gray-900 hover:text-gray-700'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('header.learns.metamask.bsc')}
                        </a>
                        <a
                          href='https://trazable.io/riesgos-de-la-inversion-en-criptomonedas/'
                          className='text-base font-medium text-gray-900 hover:text-gray-700'
                          target='_blank'
                          rel='noreferrer'
                        >
                          {t('header.learns.risks')}
                        </a>
                      </div>
                    }

                    {auth?.isAuthenticated && auth.user && (
                      <div className='py-6 px-5 space-y-4'>
                        <button className='flex-shrink-0 w-full group block focus:outline-none'>
                          <div className='flex items-center'>
                            <Avatar />
                            <div className='ml-3 text-left'>
                              <p className='text-sm font-medium text-gray-700'>{auth.user.name}</p>
                              <p className='text-xs font-medium text-gray-500 group-hover:text-gray-700'>
                                {auth.user.email}
                              </p>
                            </div>
                          </div>
                        </button>
                        <div className='grid grid-cols-1 gap-4'>
                          <Link
                            to={PAGES.PROFILE}
                            className='text-base font-medium text-gray-900 hover:text-gray-700'
                          >
                            Perfil
                          </Link>
                          <button
                            onClick={auth.signout}
                            className='w-full text-left text-base font-medium text-gray-900 hover:text-gray-700'
                          >
                            Cerrar sesión
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>
            {/* END HEADER */}

            {/* INTRO */}
            <section
              id='header'
              className={classNames(
                'pt-20 sm:pt-32 lg:pt-16 lg:pb-14 lg:overflow-hidden relative',
                {
                  'pt-48': bannerShow,
                }
              )}
            >
              <div className='mx-auto max-w-7xl lg:px-8'>
                <div className='lg:grid lg:grid-cols-12 lg:gap-8'>
                  {/* Left */}
                  <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center col-span-5'>
                    <div className='lg:py-24'>
                      <H1Bold className='mt-3 text-white sm:mt-5 lg:mt-6 z-20 text-center md:text-left '>
                        <span>
                          <Trans
                            t={t}
                            i18nKey='title'
                            components={{
                              span: (
                                <span className='pb-3 bg-clip-text text-transparent bg-gradient-to-t from-yellow-400 to-yellow-600 sm:pb-5' />
                              ),
                            }}
                          />
                        </span>
                      </H1Bold>
                      <H3Regular className='text-white mb-4 text-center md:text-left'>
                        {t('subTitle')}
                      </H3Regular>
                      <TextBodyRegular className='text-white flex text-center md:text-left'>
                        {t('description')}
                      </TextBodyRegular>
                    </div>
                  </div>
                  {/* Right */}
                  <div className='mt-4 md:mt-12 lg:m-0 lg:relative flex flex-wrap content-center col-span-7'>
                    <div className='mx-auto px-4 sm:px-6 lg:max-w-none lg:px-0 space-y-6 pb-12 lg:p-0'>
                      <div className='mt-10 sm:mt-12'>
                        <div className='mt-10 sm:mt-12 mb-12'>{heroContent}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* INTRO END */}
          </div>
          {/* SECTION UTILITIES */}
          <SectionUtilities />
          <main>
            {/* PARTNERSHIPS */}
            <section>
              <div className='max-w-7xl mx-auto py-12 lg:py-16'>
                <H2Bold className='text-center'>{t('partnerships.title')}</H2Bold>
                <div className='mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8'>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPartnershipKmzero}
                      className='object-contain'
                      height={57}
                      width={191.73}
                      style={{ maxHeight: 57 }}
                      alt='KM Zero'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPartnershipLanzadera}
                      className='object-contain'
                      height={31}
                      width={246}
                      style={{ maxHeight: 31 }}
                      alt='Lanzadera'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPartnershipMinisterioDeCiencia}
                      className='object-contain'
                      height={57}
                      width={272.1}
                      style={{ maxHeight: 57 }}
                      alt='CDTI'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPartnershipEitClimateKic}
                      className='object-contain'
                      height={57}
                      width={272.1}
                      style={{ maxHeight: 57 }}
                      alt='Climate Kic'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPartnershipEitFood}
                      className='object-contain'
                      height={57}
                      width={114}
                      style={{ maxHeight: 57 }}
                      alt='Eit Food'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPartnershipEActivate}
                      height={57}
                      width={56.41}
                      style={{ maxHeight: 57 }}
                      alt='Eactivate'
                    />
                  </div>
                </div>
              </div>
            </section>

            <div
              className='relative'
              style={{
                background: 'linear-gradient(180deg, #090214 0%, #160431 50%, #090214 100%)',
              }}
            >
              <div
                className='hidden md:block absolute h-full w-full bg-right bg-no-repeat'
                style={{ backgroundImage: `url(${imgCoinBackground})`, backgroundSize: '45%' }}
              />
              <div
                className='md:hidden absolute h-full w-full bg-right bg-no-repeat'
                style={{ backgroundImage: `url(${imgCoinBackground})`, backgroundSize: '80%' }}
              />

              {/* Feature section with grid */}
              <section id='features' className='relative py-16 sm:py-24 lg:py-32'>
                <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
                  <H4Bold className='text-white'>{t('nsSectionTokenFeatures:title')}</H4Bold>
                  <H2Bold
                    className='uppercase'
                    style={{
                      background: 'linear-gradient(71.26deg, #1FCFBF 27.74%, #0CB3A3 96.11%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {t('nsSectionTokenFeatures:subTitle')}
                  </H2Bold>
                  <H5Regular
                    className='mt-4 max-w-prose mx-auto'
                    style={{ color: 'rgba(255, 255, 255, 0.8)' }}
                  >
                    {t('nsSectionTokenFeatures:description')}
                  </H5Regular>
                  <div className='mt-12'>
                    <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4'>
                      {features.map(feature => (
                        <FeatureCard key={feature.name} {...feature} />
                      ))}
                    </div>
                  </div>
                </div>
              </section>

              {/* TOKEN INFO */}
              <section id='info' className='relative py-16 sm:py-24 lg:py-32'>
                <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
                  <H4Bold className='text-white'>{t('nsSectionTokenInfo:title')}</H4Bold>
                  <H2Bold
                    className='uppercase'
                    style={{
                      background: 'linear-gradient(71.26deg, #1FCFBF 27.74%, #0CB3A3 96.11%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {t('nsSectionTokenInfo:subTitle')}
                  </H2Bold>
                  <H5Regular
                    className='mt-4 max-w-4xl mx-auto'
                    style={{ color: 'rgba(255, 255, 255, 0.8)' }}
                  >
                    {t('nsSectionTokenInfo:description')}
                  </H5Regular>
                  <div className='mt-12'>
                    <div className=''>
                      <div className='max-w-4xl mx-auto mt-20'>
                        <img
                          src={imgTokenChart}
                          className='m-auto'
                          alt='Token distribution chart'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            {/* CTA */}
            <section style={{ backgroundColor: '#FAFAFA' }}>
              <div className='max-w-7xl mx-auto text-center py-12 px-4 sm:px-6 lg:py-16 lg:px-8'>
                <H4Bold>{t('nsSectionTokenCTA:title')}</H4Bold>
                <H2Bold
                  style={{
                    background: 'linear-gradient(71.26deg, #1FCFBF 27.74%, #0CB3A3 96.11%)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  {t('nsSectionTokenCTA:subTitle')}
                </H2Bold>
                <TextBodyRegular className='max-w-prose block m-auto'>
                  {t('nsSectionTokenCTA:description')}
                </TextBodyRegular>
                <div className='mt-8 divide-y-5'>
                  <div className='flex justify-center'>
                    <ButtonMainLink
                      href={pdfWhitepaperByLang}
                      target='_blank'
                      rel='noreferrer'
                      className='flex-grow md:flex-grow-0'
                    >
                      <DocumentTextIcon
                        className='mr-3 text-white'
                        style={{ height: 18, width: 18 }}
                      />
                      <TextButton>{t('nsSectionTokenCTA:downloadWhitepaper')}</TextButton>
                    </ButtonMainLink>
                  </div>
                  <div className='mt-8 flex justify-center flex-col md:flex-row space-y-5 md:space-y-0 md:space-x-5'>
                    <ButtonSecondaryLink
                      backgroundColor='#FAFAFA'
                      href='https://etherscan.io/address/0x394A16744Dcd805BB0cA7252e70691f0dcAc56AA#code'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <TextButton
                        style={{
                          background:
                            'linear-gradient(71.26deg, rgba(223, 153, 47, 1), rgba(207, 68, 28, 1))',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        {t('nsSectionTokenCTA:visitOurSmartContractEth')}
                      </TextButton>
                    </ButtonSecondaryLink>
                    <ButtonSecondaryLink
                      backgroundColor='#FAFAFA'
                      href='https://bscscan.com/address/0x52fe7b439753092f584917e3efea86a1cfd210f9'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <TextButton
                        style={{
                          background:
                            'linear-gradient(71.26deg, rgba(223, 153, 47, 1), rgba(207, 68, 28, 1))',
                          WebkitBackgroundClip: 'text',
                          WebkitTextFillColor: 'transparent',
                        }}
                      >
                        {t('nsSectionTokenCTA:visitOurSmartContractBSC')}
                      </TextButton>
                    </ButtonSecondaryLink>
                  </div>
                </div>
              </div>
            </section>

            {/* ROADMAP & EXCHANGES */}
            <div
              className='relative'
              style={{ backgroundImage: 'linear-gradient(rgba(22, 4, 49, 1), rgba(9, 2, 20, 1))' }}
            >
              <div
                className='absolute w-full h-full bg-no-repeat bg-cover'
                style={{ backgroundImage: `url(${imgSquaresBackground})` }}
              />

              {/* ROADMAP */}
              <section id='roadmap' className='relative pt-16 pb-6 md:pb-12'>
                <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
                  <H4Bold className='text-white'>{t('nsSectionTokenRoadmap:title')}</H4Bold>
                  <H2Bold
                    className='uppercase'
                    style={{
                      background: 'linear-gradient(71.26deg, #1FCFBF 27.74%, #0CB3A3 96.11%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {t('nsSectionTokenRoadmap:subTitle')}
                  </H2Bold>
                  <div className='mt-12'>
                    <img
                      src={roadmapDesktopImage}
                      className='mx-auto md:max-w-2xl hidden md:block'
                      alt='roadmap'
                    />
                    <img
                      src={roadmapMobileImage}
                      className='mx-auto md:max-w-2xl md:hidden'
                      alt='roadmap'
                    />
                  </div>
                </div>
              </section>

              {/* EXCHANGES */}
              {exchangeSection}
            </div>

            {/* PRESS */}
            <section>
              <div className='max-w-7xl mx-auto py-12 lg:py-16'>
                <H2Bold className='text-center'>{t('press.title')}</H2Bold>
                <div className='mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8 md:gap-y-16 lg:gap-y-24'>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPressElMundo}
                      className='object-contain m-auto'
                      height={60}
                      width={457}
                      style={{ maxHeight: 30 }}
                      alt='ElMundo'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPressBusinessInsider}
                      className='object-contain m-auto'
                      height={60}
                      width={199}
                      style={{ maxHeight: 30 }}
                      alt='BusinessInsider'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPressCoinTelegraph}
                      className='object-contain m-auto'
                      height={102}
                      width={434}
                      style={{ maxHeight: 50 }}
                      alt='CoinTelegraph'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPressEuropaPress}
                      className='object-contain m-auto'
                      height={59}
                      width={524}
                      style={{ maxHeight: 30 }}
                      alt='EuropaPress'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPressExpansion}
                      className='object-contain m-auto'
                      height={66}
                      width={292}
                      style={{ maxHeight: 33 }}
                      alt='Expansion'
                    />
                  </div>
                  <div className='col-span-1 flex justify-center py-8 px-8'>
                    <img
                      src={imgPressInformacion}
                      className='object-contain m-auto'
                      height={130}
                      width={662}
                      style={{ maxHeight: 43 }}
                      alt='Informacion'
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* NEWSLETTER */}
            <section>
              <div style={{ backgroundColor: '#102A34' }} className='py-12 lg:py-16'>
                <div
                  style={{ backgroundColor: '#102A34' }}
                  className='max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-12 md:gap-24'
                >
                  <div>
                    <H1Bold className='text-white hidden lg:block'>{t('newsLetter.title')}</H1Bold>
                    <H1Bold className='text-white text-center lg:hidden' style={{ fontSize: 50 }}>
                      {t('newsLetter.title')}
                    </H1Bold>
                    <TextBodyRegular className='text-white flex text-center md:text-left'>
                      {t('newsLetter.description')}
                    </TextBodyRegular>
                  </div>
                  <div className='relative'>
                    <div className='absolute bottom-0 hidden lg:block'>
                      <img src={imgFarmSeparator} alt='separator' />
                    </div>

                    {i18n.language.startsWith('es') ? (
                      <div id='form-acm_30630'></div>
                    ) : (
                      <div id='form-acm_30760'></div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </main>

          <Footer dark />
        </div>
      </div>

      <TelegramFloatingFollow />

      {/* Modal Auth */}
      <Modal
        show={authModalShow}
        toggle={handleToggleAuthModalShow}
        size={SIZE.SMALL}
        initialFocus={initialAuthFieldFocusRef}
      >
        <Modal.Body dismissButton toggle={handleToggleAuthModalShow}>
          <Modal.Title>
            {authModalMode === 'signin' ? t('signinForm.title') : t('signupForm.title')}
          </Modal.Title>
          {authModalMode === 'signin' && (
            <>
              <form className='text-left space-y-6 mt-5' onSubmit={formSignIn.handleSubmit}>
                <InputField
                  innerRef={initialAuthFieldFocusRef}
                  id='signin_email'
                  name='email'
                  type='email'
                  label={t('signinForm.email')}
                  autoFocus
                  onChange={formSignIn.handleChange}
                  value={formSignIn.values.email}
                  error={!!(formSignIn.touched.email && formSignIn.errors.email)}
                />

                <InputField
                  id='signin_password'
                  name='password'
                  type='password'
                  label={t('signinForm.password')}
                  onChange={formSignIn.handleChange}
                  value={formSignIn.values.password}
                  error={!!(formSignIn.touched.password && formSignIn.errors.password)}
                />

                <div>
                  <ReCAPTCHA
                    ref={signinRecaptchaRef}
                    hl={i18n.language}
                    sitekey={config.RECAPTCHA_SITEKEY}
                    onChange={token => formSignIn.setFieldValue('recaptcha', token)}
                  />
                  <p className='text-sm text-red-600'>{formSignIn.errors.recaptcha}</p>
                </div>

                <div>
                  <CheckboxField
                    id='signin_termAndConditions'
                    name='termAndConditions'
                    label={
                      <Trans
                        t={t}
                        i18nKey='signinForm.termAndConditions'
                        components={{
                          a: (
                            // eslint-disable-next-line jsx-a11y/anchor-has-content
                            <a
                              href={getTranslatedPath(location.pathname, PAGES.TERM_AND_CONDITIONS)}
                              target='_blank'
                              className='underline text-blue-500'
                              rel='noreferrer'
                            />
                          ),
                        }}
                      />
                    }
                    onChange={event => {
                      formSignIn.setFieldValue('termAndConditions', event.currentTarget.checked)
                    }}
                    checked={formSignIn.values.termAndConditions}
                  />
                  {formSignIn.touched.termAndConditions && formSignIn.errors.termAndConditions && (
                    <p className='text-sm text-red-600'>{formSignIn.errors.termAndConditions}</p>
                  )}
                </div>

                <div className='flex items-center justify-between'>
                  <div className='flex items-center'>
                    <CheckboxField
                      id='signin_remember'
                      name='remember'
                      label={t('signinForm.remember')}
                      onChange={formSignIn.handleChange}
                      checked={formSignIn.values.remember}
                    />
                  </div>
                </div>

                <div className='flex items-center justify-between'>
                  <span className='text-sm'>
                    <Link
                      to={PAGES.PASSWORD_RECOVERY}
                      className='underline font-medium text-blue-600 hover:text-blue-500'
                    >
                      {t('signinForm.recoveryPassword')}
                    </Link>
                  </span>
                </div>

                <ButtonMain type='submit' className='w-full' disabled={formSignIn.isSubmitting}>
                  <TextButton>{t('signinForm.submit')}</TextButton>
                </ButtonMain>
              </form>
            </>
          )}
        </Modal.Body>
      </Modal>

      <CookieBanner />
    </>
  )
}

export default TokenPage
