import { useTranslation } from 'react-i18next'
import UtilityPlaceholderImage from '@/assets/images/utilities/placeholder.png'
import UtilityOriginImage from '@/assets/images/utilities/origin.png'
import UtilityOpticusImage from '@/assets/images/utilities/opticus.png'
import { useMemo } from 'react'
import { URLS } from '@/constants'
import classNames from 'classnames'
import { H2Bold, H3Regular, TextBodyRegular } from './Typography'

export const SectionUtilities = (): JSX.Element => {
  const { t } = useTranslation('nsSectionUtilities')
  const utilities = useMemo(() => {
    const opticusUtility = {
      comingSoon: false,
      description: t('opticus.text'),
      imgPath: UtilityOpticusImage,
      redirectUrl: URLS.OPTICUS,
      alt: 'origin',
    }
    return [
      {
        comingSoon: false,
        description: t('origin.text'),
        imgPath: UtilityOriginImage,
        redirectUrl: URLS.ORIGIN,
        alt: 'origin',
      },

      { ...opticusUtility },
      {
        comingSoon: true,
      },
      {
        comingSoon: true,
      },
    ]
  }, [t])
  const redirectToUrl = (url: string) => {
    window.open(url, 'blank')
  }

  const getCard = (
    comingSoon = false,
    description?: string,
    imgPath?: string,
    redirectUrl?: string,
    alt?: string
  ) => {
    return (
      <div
        onClick={() => redirectUrl && redirectToUrl(redirectUrl)}
        className={classNames(
          'rounded-3xl flex-col justify-center content-center p-8 space-y-8 m-4 h-60',
          {
            'cursor-pointer': redirectUrl,
          }
        )}
        style={{
          background: 'rgba(22, 4, 50, 0.4)',
          boxShadow:
            'inset 0px 4px 8px rgba(255, 255, 255, 0.25), inset 2px 2px 12px rgba(255, 255, 255, 0.45)',
        }}
      >
        <div className='m-auto flex justify-center relative items-center h-1/2'>
          <div
            className='absolute w-full h-full'
            style={{
              background: 'rgba(16, 217, 198, 0.6)',
              filter: 'blur(40px)',
            }}
          />
          <img
            src={imgPath || UtilityPlaceholderImage}
            className='m-auto'
            alt={alt || 'placeholder'}
            style={{
              color: 'rgba(255, 255, 255, 0.2)',
              padding: 3,
            }}
          />
        </div>
        <div className=' text-center  h-1/2 flex justify-center items-center pb-8'>
          <TextBodyRegular
            className={classNames(
              {
                'text-white': !description,
                'text-gray-400': description,
              },
              'text-sm'
            )}
          >
            {description || t('comingSoon')}
          </TextBodyRegular>
        </div>
      </div>
    )
  }

  return (
    <section className='relative'>
      <div style={{ backgroundColor: '#102A34' }} className='pb-9'>
        <div className='text-center mb-8 pt-12'>
          <H2Bold className='text-white'>{t('title')}</H2Bold>
          <H3Regular className='text-gray-400'>{t('subtitle')}</H3Regular>
        </div>
        <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl'>
          <div className='grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4'>
            {utilities.map(utility =>
              getCard(
                utility.comingSoon,
                utility.description,
                utility.imgPath,
                utility.redirectUrl,
                utility.alt
              )
            )}
          </div>
        </div>
      </div>
    </section>
  )
}
